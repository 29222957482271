<template>

<div>

	<com-desktop v-if="window.is.desktop" :head="head" v-on:search="$emit('search')" />
	<com-device v-if="!window.is.desktop" :head="head" v-on:search="$emit('search')" />

</div>

</template>

<script>

export default {

	props: ['head'],

	components: {
		'com-device': () => import('./head/Device'),
		'com-desktop': () => import('./head/Desktop')
	}

}

</script>

<style scoped>

</style>
